import React from "react";
import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";
import discordLogo from "@/assets/discord.svg";
import twitterLogo from "@/assets/twitter.svg";
import ArrowRightIcon from "../Icon/ArrowRightIcon";
import Button from "../Button";

const footerButtonClass =
  "font-founders_semibold text-white tracking-wide text-[16px] md:text-[20px] md:leading-[20px] cursor-pointer border-1 flex justify-between md:justify-center items-center border-white px-[16px] py-[14px] md:px-[14px]";

export default function Footer() {
  const onOpenDiscord = () => {
    window.open("https://discord.gg/bythenai", "_blank");
  };

  const onOpenX = () => {
    window.open("https://twitter.com/bythenAI", "_blank");
  };

  const onOpenBlur = () => {
    window.open("https://blur.io/eth/collection/bythen-chip", "_blank");
  };

  return (
    <div className="bg-byteBlack-1000">
      <div className="max-w-[1920px] mx-auto">
        <div className="px-[16px] py-[40px] md:px-[24px] md:pt-[56px] md:pb-[60px] flex flex-col md:flex-row md:justify-between">
          <div className="pr-[32px] text-white font-founders_semibold text-[32px] leading-[28px] md:text-[56px] md:leading-[44px] tracking-tight">
            <p>STAY CONNECTED</p>
          </div>

          <div className="w-full md:max-w-[453px]">
            <p className="text-white text-[14px] md:text-[16px] my-[16px] md:mt-0">
              Follow our socials to make sure you don&apos;t miss out on our
              latest updates and announcements
            </p>

            <div className="flex flex-col space-x-0 space-y-[16px] md:flex-row md:space-x-[19px] md:space-y-0">
              <Button
                type="black"
                classNames={footerButtonClass}
                onClickButton={onOpenX}
              >
                <p className="md:hidden block">FOLLOW US ON X</p>
                <Image
                  unoptimized
                  width={20}
                  height={20}
                  src={twitterLogo.src}
                  alt="x"
                />
              </Button>
              <Button
                type="black"
                classNames={footerButtonClass}
                onClickButton={onOpenDiscord}
              >
                <p className="md:hidden block">FOLLOW US ON DISCORD</p>
                <Image
                  unoptimized
                  width={20}
                  height={20}
                  src={discordLogo.src}
                  alt="discord"
                />
              </Button>
              <Button
                type="black"
                classNames={footerButtonClass}
                onClickButton={onOpenBlur}
              >
                {/* <div className={`${footerButtonClass} md:px-[24px]`}> */}
                <p className="mr-[9.67px]">FIND US ON BLUR</p>
                <div className="w-[20px] aspect-square flex items-center">
                  <ArrowRightIcon color="white" />
                </div>
                {/* </div> */}
              </Button>
            </div>
          </div>
        </div>
        <div className="border-t-1 mx-0 md:mx-[24px]"></div>

        <div className="px-[16px] pt-[20px] pb-[27px] md:px-[24px] md:pt-[30px] md:pb-[36px] text-white flex justify-between">
          <div className="relative w-full max-w-[76px] md:max-w-[91.58px] aspect-[76/20]">
            <Image
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/logo_byten.svg`}
              alt="logo"
              fill
            />
          </div>
          <div className="text-[12px] md:text-[14px]">© 2024 bythen</div>
        </div>
      </div>
    </div>
  );
}
