import { ENVIRONMENT } from "@/utils/environment";
import Image from "next/image";

const Backers = [
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_5.png`,
    link: "https://east.vc/",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_6.png`,
    link: "https://guildfi.com/",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_4.png`,
    link: "https://www.beenext.com/",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_2.png`,
    link: "https://www.oskvi.com",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_7.png`,
    link: "https://appworks.tw/",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_3.png`,
    link: "https://www.ed3n.ventures/",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_8.png`,
    link: "https://x.com/XDeGods",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/ventures_1.png`,
    link: "https://skystar.vc/",
  },
];

const Contributors = [
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_dingaling.jpg`,
    name: "dingaling",
    account: "@dingalingts",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_liamtanu.jpg`,
    name: "liamtanu", //D
    account: "@liamtanu",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_graileth.jpg`,
    name: "Grail.eth",
    account: "@graildoteth",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_kongerth.jpg`,
    name: "Konger.eth",
    account: "@konger_eth",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_icedcoffee.jpg`,
    name: "Icedcoffee",
    account: "@IcedcoffeeEth",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_ubuntu08.jpg`,
    name: "ubuntu08",
    account: "@ubuntu_08",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_csaw.jpg`,
    name: "Csaw",
    account: "@Csaw07",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_xeer.jpeg`,
    name: "Xeer",
    account: "@Xeer",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_giuliox.jpg`,
    name: "GiulioX",
    account: "@GiulioXdotEth",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_renz.jpg`,
    name: "Renz",
    account: "@Renz_BDAO",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_mercheetos.jpg`,
    name: "mrcheetos",
    account: "@iammrcheetos",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_michael.jpg`,
    name: "Michael",
    account: "@MiKeMeUpP",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_jbon.jpg`,
    name: "JBond",
    account: "@jbondwagon",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_hunter_solaire.jpeg`,
    name: "Hunter Solaire",
    account: "@huntersolaire_",
  },
  {
    image: `${ENVIRONMENT.ASSETS_URL}/landing/contributor_ryanlee.jpg`,
    name: "Ryan Lee",
    account: null,
  },
];

const HOVER_CLASS =
  "hover:shadow-[6px_6px_0px_0px_#0E100F] hover:border-[#0E100F] hover:scale-105 transition-all duration-300";

export default function SectionBackers() {
  return (
    <section className="bg-[#FFFFFF]">
      <div className="px-4 py-10 lg:pt-16 lg:pb-[84px] lg:px-16 flex flex-col gap-4 overflow-hidden max-w-[1920px] mx-auto">
        <h3 className="text-center uppercase tracking-[0.1px] font-founders_semibold text-[#0E100F] text-[56px] leading-[44px] md:text-[min(10vw,_96px)] md:leading-none">
          Our Backers
        </h3>

        <div className="text-center md:max-w-[639px] mx-auto mt-4 md:mt-0 text-sm md:text-lg tracking-[0.2px]">
          The visionary supporters enabling us to transform ideas into impactful
          realities, driving the future of Advanced Avatars.
        </div>

        <div className="pt-4 pb-5 md:py-12 grid grid-cols-2 gap-y-4 md:grid-cols-8">
          {Backers.map((back, i) => (
            <a
              href={back.link}
              target="_blank"
              rel="noreferrer"
              key={i}
              className="flex items-center justify-center"
            >
              <Image
                unoptimized
                className="w-full h-auto scale-[1.4] md:scale-[2] grayscale"
                src={back.image}
                width={74}
                height={66}
                alt="ventures"
              />
            </a>
          ))}
        </div>

        <div>
          <div className="grid grid-cols-2 lg:grid-cols-4 w-full justify-between gap-9 mb-4 lg:mb-1">
            {Contributors.map((cont, i) => (
              <div
                key={cont.name}
                onClick={() => {
                  if (cont.account)
                    window.open(`https://x.com/${cont.account}`, "_blank");
                }}
                className={`cursor-pointer h-16 gap-2 md:gap-4 flex items-center border-[1px] border-[#0E100F] ${HOVER_CLASS} overflow-hidden`}
              >
                <Image
                  unoptimized
                  src={cont.image}
                  width={64}
                  height={64}
                  alt="ventures"
                />
                <div className="flex-1 max-w-[50%]">
                  <h5 className="font-medium leading-6 text-sm lg:text-md truncate max-w-[80%]">
                    {cont.name}
                  </h5>
                  {cont.account && (
                    <span className="inline-block text-xs text-[#0E100F] truncate max-w-[80%] hover:text-black/90">
                      {cont.account}
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="h-16 flex items-center text-md font-medium text-[#0E100F]/60 text-center">
              And more...
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
