export const SectionUseCaseHeader = ({ className, title, desc }) => {
  return (
    <div
      className={`block md:flex md:justify-between md:gap-8 lg:-mr-[1.25%] ${className}`}
    >
      <h1
        className={`
          whitespace-nowrap font-founders_semibold
          text-[clamp(44px,min(calc((96/1440)*100vw),calc((96/1024)*100vh)),96px)]
          leading-[clamp(36px,min(calc((76/1440)*100vw),calc((76/1024)*100vh)),76px)]
          tracking-[0.01em]
        `}
      >
        {title}
      </h1>
      <div
        className={`
          w-full md:max-w-[366px] mt-4 md:mt-0
          text-[14px] xl:text-[16px] min-[1366px]:text-[18px]
          leading-[20px] xl:leading-[24px] min-[1366px]:leading-[28px]
          tracking-[0.02em]

          [@media(max-height:768px)]:text-[14px]
          [@media(max-height:768px)]:leading-[20px]
        `}
      >
        {desc}
      </div>
    </div>
  );
};
