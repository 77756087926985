import Image from "next/image";
import { motion } from "framer-motion";
import ArrowRightIcon from "@/components/Icon/ArrowRightIcon";
import Button from "@/components/Button";
import { ParallaxText } from "@/components/ParallaxText";
import { SectionIntroduceRunningTextItem } from "./components/SectionIntroduceRunningTextItem";
import useSectionWithNavbar from "../../hooks/useSectionWithNavbar";
import TextRotateFromBottom from "../Animation/TextRotateFromBottom";
import { ENVIRONMENT } from "@/utils/environment";

export default function SectionIntroduce() {
  const sectionIntroduceRef = useSectionWithNavbar({ isBlack: false });

  const onOpenWhitePaper = () => {
    window.open("https://bythen-ai.gitbook.io/bythen-ai", "_blank");
  };

  return (
    <div
      ref={sectionIntroduceRef}
      className="relative bg-byteBlack-1000"
      style={{
        "--title-size": "min(160px,max(calc((160/1440) * 100vw),56px))",
        "--title-leading": "min(120px,max(calc((120/1440) * 100vw),44px))",
        "--title-pt": "min(292px,max(calc((292/1440) * 100vw),132px))",
        "--title-pb": "min(77px,max(calc((77/1440) * 100vw),24px))",
        "--desc-mb": "min(88px,max(calc((88/1440) * 100vw),59px))",
      }}
    >
      <div className="relative">
        <Image
          fill
          unoptimized
          src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_animate_bg.gif`}
          className="w-full h-full object-cover absolute top-0 left-0 z-0 opacity-50"
          alt="img-animate-bg"
        />
        <div className="relative z-0">
          <p className="w-full max-w-[1267px] text-center mx-auto font-founders_semibold text-[length:--title-size] leading-[var(--title-leading)] tracking-[0.01em] text-white pt-[var(--title-pt)] pb-[var(--title-pb)]">
            <TextRotateFromBottom
              whileInView="animate"
              viewport={{ once: true }}
            >
              BYTES:
              <br />
              THE FUTURE
              <br className="block sm:hidden" /> OF AVATAR
            </TextRotateFromBottom>
          </p>
          <motion.div
            className="flex flex-col items-center mx-4 xs:mx-8 md:mx-auto pb-[var(--desc-mb)] opacity-0"
            whileInView="animate"
            viewport={{ once: true }}
            variants={{
              animate: {
                opacity: 1,
                transition: { delay: 0.6 },
              },
            }}
          >
            <p className="w-full max-w-[570px] md:max-w-[660px] xl:max-w-[744px] text-center text-[14px] md:text-[16px] xl:text-[18px] text-white leading-[20px] md:leading-[24px] xl:leading-[28px] tracking-[0.02em]">
              bythen creates unique, ownable digital characters that empower
              users to build their pseudonymous social media persona, grow their
              IP, and attract followers. Our platform unlocks unlimited creative
              potential, enabling users to produce content, livestream, and
              transform their character into an AI-powered digital twin,
              elevating their online presence to the next level.
            </p>
            <Button
              type="black"
              classNames="w-full md:w-auto bg-transparent border-1 border-white text-white mt-[24px] py-[14px] px-[16px] md:py-[16px] md:px-[24px]"
              onClickButton={onOpenWhitePaper}
            >
              <span className="font-founders_semibold text-[16px] leading-[20px] md:text-[20px] md:leading-[20px] tracking-wide mr-[8px]">
                READ WHITEPAPER
              </span>
              <div className="w-[24px] relative aspect-square flex justify-center items-center">
                <ArrowRightIcon color="white" />
              </div>
            </Button>
          </motion.div>
        </div>
      </div>

      <ParallaxText baseVelocity={1} initialDirection={-1}>
        <div className="-mr-px h-[56px] md:h-[80px] flex items-center bg-[#FFAF70]">
          <SectionIntroduceRunningTextItem
            text="GOING BEYOND PROFILE PICTURES"
            icon={
              <SectionIntroduceRunningTextItem.Icon
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_user_circle.png`}
                wrapperClassName="md:p-[2px]"
              />
            }
          />
          <SectionIntroduceRunningTextItem
            text="REDEFINING DIGITAL IDENTITY"
            icon={
              <SectionIntroduceRunningTextItem.Icon
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_binary.png`}
                wrapperClassName="md:p-[2px]"
              />
            }
          />
          <SectionIntroduceRunningTextItem
            text="MEET YOUR PERSONAL AI COMPANION"
            icon={
              <SectionIntroduceRunningTextItem.Icon
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_brain.png`}
                className="mix-blend-multiply"
              />
            }
          />
          <SectionIntroduceRunningTextItem
            text="TOMORROW'S WORLD STARTS TODAY"
            icon={
              <SectionIntroduceRunningTextItem.Icon
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_world.png`}
                wrapperClassName="md:p-[2px]"
              />
            }
          />
          <SectionIntroduceRunningTextItem
            text="DEMOCRATIZING ADVANCED AVATARS"
            icon={
              <SectionIntroduceRunningTextItem.Icon
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section_2_ico_person.png`}
                wrapperClassName="p-[2px] md:p-[4px]"
              />
            }
          />
        </div>
      </ParallaxText>
    </div>
  );
}
