import { useAnimate } from "framer-motion";
import { useEffect, useState } from "react";

export const SectionContentContainer = ({
  children,
  title,
  number,
  currentActiveIndex,
  scrollToSection,
}) => {
  const collapsed = currentActiveIndex >= number;
  const active = currentActiveIndex + 1 === number;

  const [barWidth, setBarWidth] = useState(80);
  const [scope, animate] = useAnimate();

  const activeSequence = () => [
    [
      scope.current.children[0],
      { opacity: 1, pointerEvents: "auto" },
      { duration: 0.5, at: "<" },
    ],
    [
      scope.current.children[1],
      { opacity: 0, pointerEvents: "none" },
      { duration: 0.5, at: 0 },
    ],
  ];

  const inactiveSequence = () => [
    [
      scope.current.children[0],
      { opacity: 0, pointerEvents: "none" },
      { duration: 0.5, at: "-0.7" },
    ],
    [
      scope.current.children[1],
      { opacity: 1, pointerEvents: "auto" },
      { duration: 0.5, at: "<" },
    ],
  ];

  const collapsedAnimation = async () => {
    animate([
      [
        scope.current,
        { transform: `translateX(calc(-100% + ${barWidth}px))` },
        { duration: 0.5, type: "inertia" },
      ],
      ...(active ? activeSequence() : inactiveSequence()),
    ]);
  };

  const expandedAnimation = async () => {
    animate([
      [
        scope.current,
        { transform: `translateX(0)` },
        { duration: 0.5, type: "inertia" },
      ],
      ...(active ? activeSequence() : inactiveSequence()),
    ]);
  };

  const toggleCollapseAnimation = (isCollapsed) =>
    isCollapsed ? collapsedAnimation() : expandedAnimation();

  useEffect(() => {
    if (window) {
      toggleCollapseAnimation(collapsed);
    }
  }, [collapsed, active, number]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() =>
      setBarWidth(window.innerWidth < 1366 ? 60 : 80)
    );
    resizeObserver.observe(document.documentElement);
    return () => resizeObserver.disconnect();
  }, []);

  const onChangeSection = () => {
    scrollToSection(number - 1);
  };
  return (
    <div
      ref={scope}
      style={{ "--left-offset": `${barWidth * (number - 1)}px` }}
      className={`absolute h-full w-[calc(100vw-120px)] min-[1366px]:w-[calc(100vw-160px)] top-0 left-[var(--left-offset)] flex border-r border-r-byteBlack-1000 bg-white transform translate-x-0`}
      onClick={onChangeSection}
    >
      <div
        className={`h-full w-full flex justify-center items-center opacity-0 pointer-events-none`}
      >
        <div className="h-full w-full flex flex-col m-auto py-[min(2.75%,48px)] px-[min(5%,64px)]">
          {children}
        </div>
      </div>
      <div
        className={`cursor-pointer w-[60px] min-[1366px]:w-[80px] h-full flex flex-col justify-end items-center font-founders absolute top-0 right-0`}
      >
        <p className="[writing-mode:vertical-lr] text-[24px] min-[1366px]:text-[32px] leading-[28px] min-[1366px]:leading-[36px] tracking-[-1%]">
          {title}
        </p>
        <div className="w-[36px] h-[36px] min-[1366px]:w-[40px] min-[1366px]:h-[40px] mt-[20px] mb-[50px] flex justify-center items-center flex-shrink-0 border border-byteBlack-1000 rounded-full">
          <p className="-mt-1 text-[24px] leading-[28px] min-[1366px]:text-[28px] min-[1366px]:leading-[32px] tracking-[-1%]">
            {number}
          </p>
        </div>
      </div>
    </div>
  );
};
